<template>
<v-flex xs12 sm12 md12 lg12 xl12>
  <h3 v-if="!selectedHourbooking">Urenlijst van {{subject}} periode {{startDate}} tot en met {{endDate}}</h3>
  <v-data-table
  v-if="!selectedHourbooking"
  :headers="headers"
  :items="hourBookings"
  :pagination.sync="pagination"
  hide-actions
  class="elevation-1">
  <template slot="items" slot-scope="props">
      <td class="no-wrap">
        <v-btn 
        flat 
        icon 
        color="accent"
        @click="editHourbooking(props.item.id)">
          <v-icon>edit</v-icon>
        </v-btn>
      </td>    
      <td class="no-wrap">{{ formatDate(props.item.date) }}</td>
      <td class="no-wrap">{{ props.item.employeeName }}</td>
      <td class="no-wrap">{{ props.item.clientName }}</td>
      <td class="no-wrap">{{ props.item.projectName }}</td>
      <td class="no-wrap, text-xs-center">{{ props.item.amount }}</td>
      <td class="no-wrap">{{ props.item.description }}</td>
  </template>
  <template slot="footer">
    <td class="text-xs-right" colspan="5"><strong>Totaal</strong></td>
    <td class="text-xs-center">{{ total }}</td>
    <td class="text-xs-right">&nbsp;</td> 
  </template>
  </v-data-table>
  <v-btn 
  v-if="!selectedHourbooking"
  class="error ml-0" 
  @click="$emit('done')">
      Sluiten
  </v-btn>
  <hourBookingForm
    v-if="selectedHourbooking != null"
    :hourbookingdata="selectedHourbooking"
    :roles="roles"
    v-on:reset="$emit('done')"
    v-on:done="$emit('done')"
    v-on:deleteHourbooking="deleteHourbooking(selectedHourbooking)">
  </hourBookingForm>
</v-flex>
</template>


<script>
import hourBookingForm from '../components/HourBookingForm.vue'
export default {
  name: 'hourBookingReport',
  components: {
    hourBookingForm
  },
  props: ['hourBookings', 'startDate', 'endDate', 'subject', 'roles'],
  data () {
    return {
      selectedHourbooking: null,
      total: 0,
      pagination: {
        sortBy: 'date',
        rowsPerPage: -1
      },
      headers: [
        { text: '', value: 'id' },
        { text: 'Datum', value: 'date' },
        { text: 'Medewerker', value: 'employeeName' },
        { text: 'Klant', value: 'clientName' },
        { text: 'Project', value: 'projectName' },
        { text: 'Uren', value: 'amount' },
        { text: 'Omschrijving', value: 'description' }
      ]
    }
  },
  watch: {
    hourBookings(val) {
      if (val && val.length > 0) {
        this.total = val.map(b => b.amount).reduce((partial_sum, a) => partial_sum + a)
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('ddd DD-MM-YYYY')
    },
    deleteHourbooking (hourbooking) {
      this.$http.delete('api/hourbooking/' + hourbooking.id)
          .then(() => {
            this.selectedHourbooking = null
            this.hourBookings.splice(this.hourBookings.indexOf(this.hourBookings.find(x => x.id == hourbooking.id)), 1)
          })
          .catch((err) => { console.error(err) }) /* eslint-disable-line no-console */
    },
    editHourbooking(id)  {
      this.selectedHourbooking = null
      this.$http.get('api/hourbooking/' + id)
          .then(request => {
            this.selectedHourbooking = request.data
          })
          .catch((err) => { console.error(err) }) /* eslint-disable-line no-console */      
    }
  },
  mounted () {
    if (this.hourBookings && this.hourBookings.length > 0) {
      this.total = this.hourBookings.map(b => b.amount).reduce((partial_sum, a) => partial_sum + a)
    }
  }
}
</script>